
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexG_WfXOzFIdmY7nC2wskK9_5LGE8hGwIJyZEyCaXLOuMMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as checkin3LL60hyOuQ43lkdi_JMfb8aXz1Emvk4_D7RpFV_RiEwMeta } from "/vercel/path0/pages/checkin.vue?macro=true";
import { default as systemMNoywFUXKl1IOEjnSfG_5AoF5xzIXH7_45BZx5TofrUUEMeta } from "/vercel/path0/pages/settings/system.vue?macro=true";
import { default as profilei5CW5yLQYH5pidw_yW588_45d5iZY01eRFb1FNwPKJKkcMeta } from "/vercel/path0/pages/settings/profile.vue?macro=true";
import { default as securitymrgRIBjup9dK_45_453U3nxacBZQJcupJakECiwCuotj51oMeta } from "/vercel/path0/pages/settings/security.vue?macro=true";
import { default as settings9Itu3riP9CqWX2opHgufCqw701YOBx4__ysuXBnjXKQMeta } from "/vercel/path0/pages/settings.vue?macro=true";
import { default as indexFQKJnHlB25K87EFZVJWeA2ZYhVetQCgEq9JrTGVTke0Meta } from "/vercel/path0/pages/auth/index.vue?macro=true";
import { default as loginyS_635ZNODza5tjO9y_Kjsm5BWP5YDEqDSMaxBi9pVEMeta } from "/vercel/path0/pages/auth/login.vue?macro=true";
import { default as resetQ9etr4L8EAeGnw6HNAl2Wp9j5lGSwY5R_r_LMjxQ_1oMeta } from "/vercel/path0/pages/auth/reset.vue?macro=true";
import { default as logoutyXsWI_45Z19Fe7MnReEeqmPe863QSSCZ8jltIBcAa6T8QMeta } from "/vercel/path0/pages/auth/logout.vue?macro=true";
import { default as indextwlbhR_45nzO0NcmcOZ1xXY5dXeGoIc_ZbV54cYtKJZO0Meta } from "/vercel/path0/pages/hotel/index.vue?macro=true";
import { default as roomsO2Sg1WJOWSmd8zOIP_4y59uHDBcAR9zLSiW7lKWrVRoMeta } from "/vercel/path0/pages/hotel/rooms.vue?macro=true";
import { default as index7FVLveAf8GrHDrATdYAsZD0UplBRXE_I_451C_45_upLLaEMeta } from "/vercel/path0/pages/venues/index.vue?macro=true";
import { default as registerIX_45armKv67kSdaASoB7cQfyrz3LZboEMzGLID7Lgxz8Meta } from "/vercel/path0/pages/auth/register.vue?macro=true";
import { default as usersNMbqfici9e8KF_45VSwhw2_U0OZqhKW6iKR76HwG2rasoMeta } from "/vercel/path0/pages/administration/users.vue?macro=true";
import { default as administrationhopM_45iZw0imqA8knBAgN7r5fArYED0g9iS8GoSG6MboMeta } from "/vercel/path0/pages/administration.vue?macro=true";
import { default as day73bVRKA5GatGsAcRi3v6b25_4557fHqKq6jBUS8tbcAGAMeta } from "/vercel/path0/pages/attendance/day.vue?macro=true";
import { default as planf6RA1xFURudJnpA8MtFiAHgRt8r_45_1Fo_4511GXUJKmWcMeta } from "/vercel/path0/pages/dashboard/plan.vue?macro=true";
import { default as teamjr0qpK_45c9X1_kx3cQa1pvlvGtihLW0FlI4dqIKlEzUsMeta } from "/vercel/path0/pages/attendance/team.vue?macro=true";
import { default as indexcgj1YhTOlUth3jdoUCCx0gwsOKYltazOce0k7q7mnxcMeta } from "/vercel/path0/pages/dashboard/index.vue?macro=true";
import { default as index7lmkwCnAL8m0w6F66QnotlqCtt32_45aJbyYz4xWsiF_gMeta } from "/vercel/path0/pages/marketing/index.vue?macro=true";
import { default as hourseMtpFAm83EizAWb2VAVTQ5jE0itdGfyF_45Gxm4Wora84Meta } from "/vercel/path0/pages/accounting/hours.vue?macro=true";
import { default as indexSJp9DVPNPUQB6emVui__45CHvmXV_mu8N9x1RPi19ZXSkMeta } from "/vercel/path0/pages/accounting/index.vue?macro=true";
import { default as index51i6oKZPHYlt0jzopbW9NUk87W6k_451BmoYbLS_45bBjycMeta } from "/vercel/path0/pages/accounting/other/index.vue?macro=true";
import { default as offersOE3k6NxKCujhMQj6qyk6sCuU3osNvMZ_45MZOGMOqdCh0Meta } from "/vercel/path0/pages/accounting/other/offers.vue?macro=true";
import { default as invoicesgO9RGcYxa9vE3uDl6rRUgpvQK4ZquxvZzTaoUwG46f4Meta } from "/vercel/path0/pages/accounting/other/invoices.vue?macro=true";
import { default as otherVmAhcTG0NNZE0O_45wn2vsYnMomx9TlEfwoRRMxrVtegUMeta } from "/vercel/path0/pages/accounting/other.vue?macro=true";
import { default as index_H1mzeipucLiwuqV7Os0FijS83NdotUI88XYBU2AMuIMeta } from "/vercel/path0/pages/attendance/index.vue?macro=true";
import { default as heroMHtF7omXSJXQAjIFlqFI2qkVeQC1pJQvY9nw5AGyfAcMeta } from "/vercel/path0/pages/boxcatering/hero.vue?macro=true";
import { default as shopGIjC7aV6zEwKbJA5JPJDlLE1O19UuhQYvrFDfe2XezoMeta } from "/vercel/path0/pages/boxcatering/shop.vue?macro=true";
import { default as analyticsI1TO1YJRdsRATMZJEqkZ4kueEqDrqxEg2XHR5l_FMG8Meta } from "/vercel/path0/pages/venues/analytics.vue?macro=true";
import { default as allogBGjhvd2pABwH_4553shQz6NYG_450jGZeGpjaJ0WwPMugMeta } from "/vercel/path0/pages/venues/leads/all.vue?macro=true";
import { default as locations2vKO9TPXmVv93eXRi4XJRgWTNmdZmcwFpaRf1ErwwYgMeta } from "/vercel/path0/pages/venues/locations.vue?macro=true";
import { default as templatesXUePPQ9Xxt48jif1DBwU_M6_SnB3fatq90Fsz3xpnFUMeta } from "/vercel/path0/pages/venues/templates.vue?macro=true";
import { default as index5gsqeAmCw2ccWjjjEHTOO7Hu9SoeqThklnXq0KSvEPAMeta } from "/vercel/path0/pages/boxcatering/index.vue?macro=true";
import { default as itemsVblzLGhE1y_bQ2Dhb_45_pxbnlW1F_DnrDr8Zb3Kf_45_45osMeta } from "/vercel/path0/pages/boxcatering/items.vue?macro=true";
import { default as ticketsL_45xmEaoWkgugSTvPYNoUUA2os7LYFL3lAPrjulL4zWMMeta } from "/vercel/path0/pages/dashboard/tickets.vue?macro=true";
import { default as clientsjecO0_feP21s1ODEJhzvVeVrKMI1Wo_45NKXdzpBlBZrwMeta } from "/vercel/path0/pages/marketing/clients.vue?macro=true";
import { default as index2hs6bxsKpaRRFMWNc4yaMzvhLr0Vso49RNIu1fk6sIMMeta } from "/vercel/path0/pages/millerscake/index.vue?macro=true";
import { default as itemsmwhfbMM0yWTH_45x7gT3E3qSZgY2KI7kt4AsiKqP_45rbIQMeta } from "/vercel/path0/pages/millerscake/items.vue?macro=true";
import { default as categoriescug_45SZKzUr_45Ds8h0B6bQsbDVmUVvdUVrAcoEeVQ25AQMeta } from "/vercel/path0/pages/venues/categories.vue?macro=true";
import { default as _91id_93SxBLGxZtBtDd9VAdhU_4x2ZxVv0kFRFiUKrLxPIZeNwMeta } from "/vercel/path0/pages/venues/menus/[id].vue?macro=true";
import { default as bills5Q4dktM7eVWar0N49OIR2BwnQYyUqFcfX_pjugE_eB8Meta } from "/vercel/path0/pages/acquisitions/bills.vue?macro=true";
import { default as indexS99fszo9p76R3IIJosS8waZPqUyz641jmNi7sO02vXYMeta } from "/vercel/path0/pages/acquisitions/index.vue?macro=true";
import { default as checkinEmvJY4i_45OTFSY_Wd17xEYCDZ4fMhiTxiaiiLC0L9pwwMeta } from "/vercel/path0/pages/attendance/checkin.vue?macro=true";
import { default as orders8r_k5pluGgiBjQhrOvsbZBMGN4wuPK2XWmvig0urx2gMeta } from "/vercel/path0/pages/boxcatering/orders.vue?macro=true";
import { default as invoicesnpd9JOvH21u_45l_45JMBghYLiNTsMJ_9NpqV5zFLMCHh7oMeta } from "/vercel/path0/pages/dashboard/invoices.vue?macro=true";
import { default as vouchers4Lbki_oc3zzxDQ9B0KC_45HkOvlgbYIhnLrFEUzLz_bQ0Meta } from "/vercel/path0/pages/dashboard/vouchers.vue?macro=true";
import { default as ordersiKYvxtT_451J8El2FcetW_45xyavA8sVoEElsfbBVObvoY0Meta } from "/vercel/path0/pages/millerscake/orders.vue?macro=true";
import { default as _91id_93yc14Nv_45W0X7pD0_jdmqHEMS5Di_W3_r5Q1tgpxm4i24Meta } from "/vercel/path0/pages/venues/events/[id].vue?macro=true";
import { default as list9wsnVUpWUCeMq6x25DEHLhRtpG_45zZCBr8blle_kXpI0Meta } from "/vercel/path0/pages/venues/events/list.vue?macro=true";
import { default as indexMJjX3Zob96eGynha6Q_uGcNSJvCGD9CQsp9arFD0urwMeta } from "/vercel/path0/pages/venues/menus/index.vue?macro=true";
import { default as _91id_93LK0VorWe7QrIn9mOXn0JZNxT2uTF9Eati_45d0CKL7y4oMeta } from "/vercel/path0/pages/venues/offers/[id].vue?macro=true";
import { default as listuCtkyisKUDVcQ38HcME6Bg_41fCWby68AV6Acf1iq_45wMeta } from "/vercel/path0/pages/hotel/bookings/list.vue?macro=true";
import { default as catalogENteFGrB0bxtdU3S840i4taueHObYKMc3v3dqNjYWjQMeta } from "/vercel/path0/pages/millerscake/catalog.vue?macro=true";
import { default as indexmJNy76dp4ribblRqpepUQ1OuBk4QCP9UHDAp1mZXzOcMeta } from "/vercel/path0/pages/venues/events/index.vue?macro=true";
import { default as funnele6w3jLWA3ku4AXhwLP8y6E_45a7vuTJtBHpIzxLZ_453EUkMeta } from "/vercel/path0/pages/venues/leads/funnel.vue?macro=true";
import { default as indexVGlWUheNHViN3IuQK8a92JwavvOL_45SET7yoLLFsRHW4Meta } from "/vercel/path0/pages/venues/offers/index.vue?macro=true";
import { default as employeescBjSTndk_IG8OapH9C37x1mV_KGiAWkU_45T69fFpyAiMMeta } from "/vercel/path0/pages/accounting/employees.vue?macro=true";
import { default as day_46userslylrlv7ohyNJUn7579715BxTtQUKy6oRUUXtg5lgofEMeta } from "/vercel/path0/pages/attendance/day.users.vue?macro=true";
import { default as employeesG660T7SiR5O2sz_45hIfAVEqQNFDTraGctD6xOVvBq9lwMeta } from "/vercel/path0/pages/attendance/employees.vue?macro=true";
import { default as locationsuZOkaAPO_45XrjWsgJ24dp8VWr9tw3a_Ay8Los3ZBYXQkMeta } from "/vercel/path0/pages/attendance/locations.vue?macro=true";
import { default as indexIqTi6N26gHADHrFL6cK84hF1ec2XIgzs19bH8JFzkPMMeta } from "/vercel/path0/pages/hotel/bookings/index.vue?macro=true";
import { default as analyticsKD3X3OFRksCaDB_vVhllhiR1ka1HOYneVmrvsyTbyDYMeta } from "/vercel/path0/pages/boxcatering/analytics.vue?macro=true";
import { default as inquirieszBDFmKIL5dwt1wZ5o6wlt_S4THhuTlBBMiBIDefxrQ8Meta } from "/vercel/path0/pages/boxcatering/inquiries.vue?macro=true";
import { default as analyticswjfQIRER6Y9uQVBknFzu1iQgL23Jc1D8R_45EJhxGhh6sMeta } from "/vercel/path0/pages/millerscake/analytics.vue?macro=true";
import { default as notifications_45m97D7xD2UWBNZPoavzraESP6hk4AEG8ni_XUE3TswMMeta } from "/vercel/path0/pages/profile/notifications.vue?macro=true";
import { default as plannerQpQnqNF_45_Hih7D36JfzfObowyuYmiMjU_45ZZUN9eqWToMeta } from "/vercel/path0/pages/venues/events/planner.vue?macro=true";
import { default as all9c4bUXkpBqS7mz87Ktx_45c58qJzIQEaw6G3CpH02polUMeta } from "/vercel/path0/pages/accounting/reports/all.vue?macro=true";
import { default as suppliersrlY9fmCkWqYT_45Ryjau11D_45MuvOn3EZKOzUK_NGrv1V8Meta } from "/vercel/path0/pages/acquisitions/suppliers.vue?macro=true";
import { default as _91id_93iEDkduAhOwApEh080ZKqd3OPG9MUUMgRQSDNCnJS3R4Meta } from "/vercel/path0/pages/boxcatering/menus/[id].vue?macro=true";
import { default as calendar4HrcReDBATavtkGw71pg8bxXnCriykmQ3HL4xc_cnX8Meta } from "/vercel/path0/pages/venues/events/calendar.vue?macro=true";
import { default as analyticskbIBhvop9MVjsLEZqRbL6UzE1x49qUsmYbyUS5LJnTsMeta } from "/vercel/path0/pages/venues/leads/analytics.vue?macro=true";
import { default as call_45listjWaakpk9ASvoPhtK938yIVKulRYwmM5h2kEY8vATwBQMeta } from "/vercel/path0/pages/venues/leads/call-list.vue?macro=true";
import { default as userRp7deyjr0bwfuctg6Lna6LWgaVBy7hnesdOBFBOp9TUMeta } from "/vercel/path0/pages/accounting/reports/user.vue?macro=true";
import { default as ingredientszF9DLng_45dDfWVqFcinAbvnYA9aGV7P5tfgCs_sUx9_8Meta } from "/vercel/path0/pages/boxcatering/ingredients.vue?macro=true";
import { default as indexjZBsWh6nvQdReWhNNM_45i1JAawxgJyGxllCaW5_2Op3sMeta } from "/vercel/path0/pages/boxcatering/menus/index.vue?macro=true";
import { default as _91id_93zy0SWwRvXsNmhTVZ1kdWBqiCHJgGwd6IrMReD2lfjiwMeta } from "/vercel/path0/pages/boxcatering/offers/[id].vue?macro=true";
import { default as calendardwm_45rtZLhM5FOT6ktPtIRE92DOa5IS3Q3nm_VkAKxrkMeta } from "/vercel/path0/pages/hotel/bookings/calendar.vue?macro=true";
import { default as _91id_93xd4YiIb06RNok_XnHJGgTBGg2GaWWwuELEYvMnJHf4AMeta } from "/vercel/path0/pages/millerscake/offers/[id].vue?macro=true";
import { default as indexV7Rq5RMWIB42eni6BEvyk9Q65njG_dyR4LLvoLr5wiUMeta } from "/vercel/path0/pages/accounting/reports/index.vue?macro=true";
import { default as _91id_93aXD08rAnCVljr5fbB9vz9guL1nCLxre98W7oIJNVJysMeta } from "/vercel/path0/pages/acquisitions/orders/[id].vue?macro=true";
import { default as index45dTVNKEutlJ_QkDDIe5H9CMuAAK_45iz0_B0dvk1m17MMeta } from "/vercel/path0/pages/boxcatering/offers/index.vue?macro=true";
import { default as _91id_93MKsNtnvxBiWP1VB2zj0dspnns8arI8sA2YFOXTtMP4AMeta } from "/vercel/path0/pages/marketing/campaigns/[id].vue?macro=true";
import { default as indextM_45ofWTHAt01go3mDl7S1A6jcHtSt_4535d8ZZcRg_45_D4Meta } from "/vercel/path0/pages/millerscake/offers/index.vue?macro=true";
import { default as unassignedx7gbXiuz3hyX4og9KuBge6W8sfPqZwklvqQ_Q6rtUtcMeta } from "/vercel/path0/pages/venues/events/unassigned.vue?macro=true";
import { default as indexxIJkI_45Fgd9DNIauyYGmrl0zCLq2RhfyiYsF16eZlj40Meta } from "/vercel/path0/pages/acquisitions/orders/index.vue?macro=true";
import { default as indexIMx1Pus1BK2Jw_45sMaW4WvgAxtACGLTdRZhPLhqjhGXAMeta } from "/vercel/path0/pages/marketing/campaigns/index.vue?macro=true";
import { default as _91userId_931u85FJihwHRHiXN_QumrPU5pmOktf4LLiT9MRTXPmR8Meta } from "/vercel/path0/pages/attendance/history/[userId].vue?macro=true";
import { default as calendaryigW9lHY9FgCpEY3QLmk_sNPWafcbZkKQRfs4JTUi6QMeta } from "/vercel/path0/pages/acquisitions/orders/calendar.vue?macro=true";
import { default as _91recoveryToken_93hzBq4pk0YIPIEaa1cuilxbqMrONBLbR350uoLvxGumUMeta } from "/vercel/path0/pages/auth/recover/[recoveryToken].vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexG_WfXOzFIdmY7nC2wskK9_5LGE8hGwIJyZEyCaXLOuMMeta || {},
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "checkin",
    path: "/checkin",
    meta: checkin3LL60hyOuQ43lkdi_JMfb8aXz1Emvk4_D7RpFV_RiEwMeta || {},
    component: () => import("/vercel/path0/pages/checkin.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/vercel/path0/pages/settings.vue"),
    children: [
  {
    name: "settings-system",
    path: "system",
    component: () => import("/vercel/path0/pages/settings/system.vue")
  },
  {
    name: "settings-profile",
    path: "profile",
    component: () => import("/vercel/path0/pages/settings/profile.vue")
  },
  {
    name: "settings-security",
    path: "security",
    component: () => import("/vercel/path0/pages/settings/security.vue")
  }
]
  },
  {
    name: "auth",
    path: "/auth",
    component: () => import("/vercel/path0/pages/auth/index.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginyS_635ZNODza5tjO9y_Kjsm5BWP5YDEqDSMaxBi9pVEMeta || {},
    component: () => import("/vercel/path0/pages/auth/login.vue")
  },
  {
    name: "auth-reset",
    path: "/auth/reset",
    meta: resetQ9etr4L8EAeGnw6HNAl2Wp9j5lGSwY5R_r_LMjxQ_1oMeta || {},
    component: () => import("/vercel/path0/pages/auth/reset.vue")
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    meta: logoutyXsWI_45Z19Fe7MnReEeqmPe863QSSCZ8jltIBcAa6T8QMeta || {},
    component: () => import("/vercel/path0/pages/auth/logout.vue")
  },
  {
    name: "hotel",
    path: "/hotel",
    component: () => import("/vercel/path0/pages/hotel/index.vue")
  },
  {
    name: "hotel-rooms",
    path: "/hotel/rooms",
    component: () => import("/vercel/path0/pages/hotel/rooms.vue")
  },
  {
    name: "venues",
    path: "/venues",
    component: () => import("/vercel/path0/pages/venues/index.vue")
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: registerIX_45armKv67kSdaASoB7cQfyrz3LZboEMzGLID7Lgxz8Meta || {},
    component: () => import("/vercel/path0/pages/auth/register.vue")
  },
  {
    name: "administration",
    path: "/administration",
    component: () => import("/vercel/path0/pages/administration.vue"),
    children: [
  {
    name: "administration-users",
    path: "users",
    component: () => import("/vercel/path0/pages/administration/users.vue")
  }
]
  },
  {
    name: "attendance-day",
    path: "/attendance/day",
    component: () => import("/vercel/path0/pages/attendance/day.vue")
  },
  {
    name: "dashboard-plan",
    path: "/dashboard/plan",
    component: () => import("/vercel/path0/pages/dashboard/plan.vue")
  },
  {
    name: "attendance-team",
    path: "/attendance/team",
    component: () => import("/vercel/path0/pages/attendance/team.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/vercel/path0/pages/dashboard/index.vue")
  },
  {
    name: "marketing",
    path: "/marketing",
    component: () => import("/vercel/path0/pages/marketing/index.vue")
  },
  {
    name: "accounting-hours",
    path: "/accounting/hours",
    component: () => import("/vercel/path0/pages/accounting/hours.vue")
  },
  {
    name: "accounting",
    path: "/accounting",
    component: () => import("/vercel/path0/pages/accounting/index.vue")
  },
  {
    name: otherVmAhcTG0NNZE0O_45wn2vsYnMomx9TlEfwoRRMxrVtegUMeta?.name,
    path: "/accounting/other",
    component: () => import("/vercel/path0/pages/accounting/other.vue"),
    children: [
  {
    name: "accounting-other",
    path: "",
    component: () => import("/vercel/path0/pages/accounting/other/index.vue")
  },
  {
    name: "accounting-other-offers",
    path: "offers",
    component: () => import("/vercel/path0/pages/accounting/other/offers.vue")
  },
  {
    name: "accounting-other-invoices",
    path: "invoices",
    component: () => import("/vercel/path0/pages/accounting/other/invoices.vue")
  }
]
  },
  {
    name: "attendance",
    path: "/attendance",
    component: () => import("/vercel/path0/pages/attendance/index.vue")
  },
  {
    name: "boxcatering-hero",
    path: "/boxcatering/hero",
    component: () => import("/vercel/path0/pages/boxcatering/hero.vue")
  },
  {
    name: "boxcatering-shop",
    path: "/boxcatering/shop",
    component: () => import("/vercel/path0/pages/boxcatering/shop.vue")
  },
  {
    name: "venues-analytics",
    path: "/venues/analytics",
    component: () => import("/vercel/path0/pages/venues/analytics.vue")
  },
  {
    name: "venues-leads-all",
    path: "/venues/leads/all",
    component: () => import("/vercel/path0/pages/venues/leads/all.vue")
  },
  {
    name: "venues-locations",
    path: "/venues/locations",
    component: () => import("/vercel/path0/pages/venues/locations.vue")
  },
  {
    name: "venues-templates",
    path: "/venues/templates",
    component: () => import("/vercel/path0/pages/venues/templates.vue")
  },
  {
    name: "boxcatering",
    path: "/boxcatering",
    component: () => import("/vercel/path0/pages/boxcatering/index.vue")
  },
  {
    name: "boxcatering-items",
    path: "/boxcatering/items",
    component: () => import("/vercel/path0/pages/boxcatering/items.vue")
  },
  {
    name: "dashboard-tickets",
    path: "/dashboard/tickets",
    component: () => import("/vercel/path0/pages/dashboard/tickets.vue")
  },
  {
    name: "marketing-clients",
    path: "/marketing/clients",
    component: () => import("/vercel/path0/pages/marketing/clients.vue")
  },
  {
    name: "millerscake",
    path: "/millerscake",
    component: () => import("/vercel/path0/pages/millerscake/index.vue")
  },
  {
    name: "millerscake-items",
    path: "/millerscake/items",
    component: () => import("/vercel/path0/pages/millerscake/items.vue")
  },
  {
    name: "venues-categories",
    path: "/venues/categories",
    component: () => import("/vercel/path0/pages/venues/categories.vue")
  },
  {
    name: "venues-menus-id",
    path: "/venues/menus/:id()",
    component: () => import("/vercel/path0/pages/venues/menus/[id].vue")
  },
  {
    name: "acquisitions-bills",
    path: "/acquisitions/bills",
    component: () => import("/vercel/path0/pages/acquisitions/bills.vue")
  },
  {
    name: "acquisitions",
    path: "/acquisitions",
    component: () => import("/vercel/path0/pages/acquisitions/index.vue")
  },
  {
    name: "attendance-checkin",
    path: "/attendance/checkin",
    component: () => import("/vercel/path0/pages/attendance/checkin.vue")
  },
  {
    name: "boxcatering-orders",
    path: "/boxcatering/orders",
    component: () => import("/vercel/path0/pages/boxcatering/orders.vue")
  },
  {
    name: "dashboard-invoices",
    path: "/dashboard/invoices",
    component: () => import("/vercel/path0/pages/dashboard/invoices.vue")
  },
  {
    name: "dashboard-vouchers",
    path: "/dashboard/vouchers",
    component: () => import("/vercel/path0/pages/dashboard/vouchers.vue")
  },
  {
    name: "millerscake-orders",
    path: "/millerscake/orders",
    component: () => import("/vercel/path0/pages/millerscake/orders.vue")
  },
  {
    name: "venues-events-id",
    path: "/venues/events/:id()",
    component: () => import("/vercel/path0/pages/venues/events/[id].vue")
  },
  {
    name: "venues-events-list",
    path: "/venues/events/list",
    component: () => import("/vercel/path0/pages/venues/events/list.vue")
  },
  {
    name: "venues-menus",
    path: "/venues/menus",
    component: () => import("/vercel/path0/pages/venues/menus/index.vue")
  },
  {
    name: "venues-offers-id",
    path: "/venues/offers/:id()",
    component: () => import("/vercel/path0/pages/venues/offers/[id].vue")
  },
  {
    name: "hotel-bookings-list",
    path: "/hotel/bookings/list",
    component: () => import("/vercel/path0/pages/hotel/bookings/list.vue")
  },
  {
    name: "millerscake-catalog",
    path: "/millerscake/catalog",
    component: () => import("/vercel/path0/pages/millerscake/catalog.vue")
  },
  {
    name: "venues-events",
    path: "/venues/events",
    component: () => import("/vercel/path0/pages/venues/events/index.vue")
  },
  {
    name: "venues-leads-funnel",
    path: "/venues/leads/funnel",
    component: () => import("/vercel/path0/pages/venues/leads/funnel.vue")
  },
  {
    name: "venues-offers",
    path: "/venues/offers",
    component: () => import("/vercel/path0/pages/venues/offers/index.vue")
  },
  {
    name: "accounting-employees",
    path: "/accounting/employees",
    component: () => import("/vercel/path0/pages/accounting/employees.vue")
  },
  {
    name: "attendance-day.users",
    path: "/attendance/day.users",
    component: () => import("/vercel/path0/pages/attendance/day.users.vue")
  },
  {
    name: "attendance-employees",
    path: "/attendance/employees",
    component: () => import("/vercel/path0/pages/attendance/employees.vue")
  },
  {
    name: "attendance-locations",
    path: "/attendance/locations",
    component: () => import("/vercel/path0/pages/attendance/locations.vue")
  },
  {
    name: "hotel-bookings",
    path: "/hotel/bookings",
    component: () => import("/vercel/path0/pages/hotel/bookings/index.vue")
  },
  {
    name: "boxcatering-analytics",
    path: "/boxcatering/analytics",
    component: () => import("/vercel/path0/pages/boxcatering/analytics.vue")
  },
  {
    name: "boxcatering-inquiries",
    path: "/boxcatering/inquiries",
    component: () => import("/vercel/path0/pages/boxcatering/inquiries.vue")
  },
  {
    name: "millerscake-analytics",
    path: "/millerscake/analytics",
    component: () => import("/vercel/path0/pages/millerscake/analytics.vue")
  },
  {
    name: "profile-notifications",
    path: "/profile/notifications",
    component: () => import("/vercel/path0/pages/profile/notifications.vue")
  },
  {
    name: "venues-events-planner",
    path: "/venues/events/planner",
    component: () => import("/vercel/path0/pages/venues/events/planner.vue")
  },
  {
    name: "accounting-reports-all",
    path: "/accounting/reports/all",
    component: () => import("/vercel/path0/pages/accounting/reports/all.vue")
  },
  {
    name: "acquisitions-suppliers",
    path: "/acquisitions/suppliers",
    component: () => import("/vercel/path0/pages/acquisitions/suppliers.vue")
  },
  {
    name: "boxcatering-menus-id",
    path: "/boxcatering/menus/:id()",
    component: () => import("/vercel/path0/pages/boxcatering/menus/[id].vue")
  },
  {
    name: "venues-events-calendar",
    path: "/venues/events/calendar",
    component: () => import("/vercel/path0/pages/venues/events/calendar.vue")
  },
  {
    name: "venues-leads-analytics",
    path: "/venues/leads/analytics",
    component: () => import("/vercel/path0/pages/venues/leads/analytics.vue")
  },
  {
    name: "venues-leads-call-list",
    path: "/venues/leads/call-list",
    component: () => import("/vercel/path0/pages/venues/leads/call-list.vue")
  },
  {
    name: "accounting-reports-user",
    path: "/accounting/reports/user",
    component: () => import("/vercel/path0/pages/accounting/reports/user.vue")
  },
  {
    name: "boxcatering-ingredients",
    path: "/boxcatering/ingredients",
    component: () => import("/vercel/path0/pages/boxcatering/ingredients.vue")
  },
  {
    name: "boxcatering-menus",
    path: "/boxcatering/menus",
    component: () => import("/vercel/path0/pages/boxcatering/menus/index.vue")
  },
  {
    name: "boxcatering-offers-id",
    path: "/boxcatering/offers/:id()",
    component: () => import("/vercel/path0/pages/boxcatering/offers/[id].vue")
  },
  {
    name: "hotel-bookings-calendar",
    path: "/hotel/bookings/calendar",
    component: () => import("/vercel/path0/pages/hotel/bookings/calendar.vue")
  },
  {
    name: "millerscake-offers-id",
    path: "/millerscake/offers/:id()",
    component: () => import("/vercel/path0/pages/millerscake/offers/[id].vue")
  },
  {
    name: "accounting-reports",
    path: "/accounting/reports",
    component: () => import("/vercel/path0/pages/accounting/reports/index.vue")
  },
  {
    name: "acquisitions-orders-id",
    path: "/acquisitions/orders/:id()",
    component: () => import("/vercel/path0/pages/acquisitions/orders/[id].vue")
  },
  {
    name: "boxcatering-offers",
    path: "/boxcatering/offers",
    component: () => import("/vercel/path0/pages/boxcatering/offers/index.vue")
  },
  {
    name: "marketing-campaigns-id",
    path: "/marketing/campaigns/:id()",
    component: () => import("/vercel/path0/pages/marketing/campaigns/[id].vue")
  },
  {
    name: "millerscake-offers",
    path: "/millerscake/offers",
    component: () => import("/vercel/path0/pages/millerscake/offers/index.vue")
  },
  {
    name: "venues-events-unassigned",
    path: "/venues/events/unassigned",
    component: () => import("/vercel/path0/pages/venues/events/unassigned.vue")
  },
  {
    name: "acquisitions-orders",
    path: "/acquisitions/orders",
    component: () => import("/vercel/path0/pages/acquisitions/orders/index.vue")
  },
  {
    name: "marketing-campaigns",
    path: "/marketing/campaigns",
    component: () => import("/vercel/path0/pages/marketing/campaigns/index.vue")
  },
  {
    name: "attendance-history-userId",
    path: "/attendance/history/:userId()",
    component: () => import("/vercel/path0/pages/attendance/history/[userId].vue")
  },
  {
    name: "acquisitions-orders-calendar",
    path: "/acquisitions/orders/calendar",
    component: () => import("/vercel/path0/pages/acquisitions/orders/calendar.vue")
  },
  {
    name: "auth-recover-recoveryToken",
    path: "/auth/recover/:recoveryToken()",
    meta: _91recoveryToken_93hzBq4pk0YIPIEaa1cuilxbqMrONBLbR350uoLvxGumUMeta || {},
    component: () => import("/vercel/path0/pages/auth/recover/[recoveryToken].vue")
  }
]