export const usePreferences = defineStore('preferences', {
  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
    paths: [
      "uiPrimary",
      "uiGray",
      "demoMode",
      "invoicesDisplay",
      "itemsPerPage",
      "baseOffersStatusTab",
      "baseOrdersStatusTab",
      "baseOrdersTypeTab",
      "notificationModules",
    ],
  },
  state: () => ({
    uiPrimary: "amber",
    uiGray: "cool",
    demoMode: false,
    invoicesDisplay: "single",
    itemsPerPage: 10,
    baseOffersStatusTab: 2,
    baseOrdersStatusTab: 2,
    baseOrdersTypeTab: 1,
    notificationModules: JSON.parse(JSON.stringify(notificationModulesMap)),
  }),
  actions: {
    set(preference: string, value: any) {
      this[preference] = value;
    },
  },
});
