export const useLocations = defineStore('locations', {
  state: () => ({
    loaded: false,
    attendanceLocations: [],
    venueLocations: [],
    venueHalls: [],
  }),
  actions: {
    fetchAll() {
      useApi("GET", "/misc/load-locations").then((res: any) => {
        if (res) {
          this.attendanceLocations = res.attendance_locations;
          this.venueLocations = res.venue_locations;
          this.venueHalls = res.venue_halls;

          this.loaded = true;
        }
      })
    },
  },
});
