import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_jjl2DFTrQxMG7TqNyE_rvcIV8r2uFVLO_Sius2B7lXg from "/vercel/path0/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg from "/vercel/path0/.nuxt/pwa-icons-plugin.ts";
import slideovers_g1RyjnFCC2XCqS3NLpv7yngCZ5AzjpmT7UJr_dW3BbY from "/vercel/path0/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_JhH8M1KzF3pQyhcHsoNTBLd8tKGet6zo2zTBVDe7nK4 from "/vercel/path0/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_E7kSti5pGZ28QhUUurq6gGRU3l65WuXO_KJC3GQgzFo from "/vercel/path0/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg from "/vercel/path0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8 from "/vercel/path0/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_iT8ELLbMNFiLNeAxBqCmeEcEDkpIt1C7ZqsUGUpbugo from "/vercel/path0/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_LwC6IsSsBex_IF7Qh_Tr0Ju_YI83g0Rn778LE_9f2C0 from "/vercel/path0/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_K7Nv7CLS9pqnKvPo4_D8Anr6utXepigxZ56Q7G93_4k from "/vercel/path0/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import errorHandler_client_tcWdFRaPJbsniHtfI40KiofcYWjxTTvGhJq6caEqxyM from "/vercel/path0/plugins/errorHandler.client.ts";
import onesignal_client_L69e_mwspgKR6p1_KbeI1cOhW1bU9m2MtK0x9QwlumI from "/vercel/path0/plugins/onesignal.client.ts";
import releaseCacheHandler_iT1lzpoet3l4B_Co7kKgTnFdob8wngp5Ro8NZXG3Phg from "/vercel/path0/plugins/releaseCacheHandler.ts";
import sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE from "/vercel/path0/plugins/sentry.client.ts";
import vue3_smooth_dnd_client_8pcuCxiAKse9I7tcz7MadyrUMrbGv3t4b_WMyr6FI_0 from "/vercel/path0/plugins/vue3-smooth-dnd.client.ts";
import pwa_client_FQM5r5C96_zrgtYZL3Jbj_skHFBc_M2JlWere1x75dQ from "/vercel/path0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_jjl2DFTrQxMG7TqNyE_rvcIV8r2uFVLO_Sius2B7lXg,
  pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg,
  slideovers_g1RyjnFCC2XCqS3NLpv7yngCZ5AzjpmT7UJr_dW3BbY,
  modals_JhH8M1KzF3pQyhcHsoNTBLd8tKGet6zo2zTBVDe7nK4,
  colors_E7kSti5pGZ28QhUUurq6gGRU3l65WuXO_KJC3GQgzFo,
  plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg,
  plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8,
  scrollbars_client_iT8ELLbMNFiLNeAxBqCmeEcEDkpIt1C7ZqsUGUpbugo,
  presets_LwC6IsSsBex_IF7Qh_Tr0Ju_YI83g0Rn778LE_9f2C0,
  variables_K7Nv7CLS9pqnKvPo4_D8Anr6utXepigxZ56Q7G93_4k,
  errorHandler_client_tcWdFRaPJbsniHtfI40KiofcYWjxTTvGhJq6caEqxyM,
  onesignal_client_L69e_mwspgKR6p1_KbeI1cOhW1bU9m2MtK0x9QwlumI,
  releaseCacheHandler_iT1lzpoet3l4B_Co7kKgTnFdob8wngp5Ro8NZXG3Phg,
  sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE,
  vue3_smooth_dnd_client_8pcuCxiAKse9I7tcz7MadyrUMrbGv3t4b_WMyr6FI_0,
  pwa_client_FQM5r5C96_zrgtYZL3Jbj_skHFBc_M2JlWere1x75dQ
]