export default defineAppConfig({
  ui: {
    primary: "teal",
    gray: "zinc",
    select: {
      base: "min-w-44"
    },
    table: {
      base: "relative",
      thead: "bg-background/75 backdrop-blur sticky top-0 z-10",
    },
    card: {
      background: 
      "bg-gray-100/50 dark:bg-gray-800/25",
    },
    page: {
      card: {
        wrapper:
          "cursor-pointer hover:ring-2 hover:ring-primary-500 dark:hover:ring-primary-400 bg-gray-100/50 dark:bg-gray-800/50 hover:bg-gray-100/50 dark:hover:bg-gray-800/50",
      },
    },
    dashboard: {
      navbar: {
        toggle: {
          base: "hidden"
        }
      },
      page: {
        wrapper: "overflow-y-scroll",
      },
      sidebar: {
        links: {
          active: "before:bg-primary-100 dark:before:bg-primary-950",
          inactive: 'hover:before:bg-primary/5 dark:hover:before:bg-gray-800/50',
        },
      },
      modal: {
        height: "min-h-dvh h-full md:h-fit md:min-h-min",
        width: "sm:max-w-md"
      },
    }
  },
});
