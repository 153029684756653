export const notificationModules = [
  {
    value: 'venues',
    label: 'Evenimente',
  },
  {
    value: 'boxcatering',
    label: 'Boxcatering',
  },
  {
    value: 'millerscake',
    label: 'Millerscake',
  },
  {
    value: 'attendance',
    label: 'Prezenta',
  },
  {
    value: 'accounting',
    label: 'Contabilitate',
  },
]

export const notificationModulesMap = notificationModules.map((category) => category.value)
